import React, { useCallback } from 'react';

import KJUR from 'jsrsasign';
import { CircularProgress } from '@material-ui/core';
import { ZoomMtg } from '@zoom/meetingsdk';
import queryString from 'query-string';
import { useAsync } from 'react-use';
import styled, { createGlobalStyle } from 'styled-components';

import useServerTime from './hooks/useServerTime';

const GlobalStyle = createGlobalStyle`
  #content_container {
    overflow: hidden auto;
  }
`;

const CustomCircularProgress = styled(CircularProgress)`
  color: 'white',
  marginRight: '1rem',
`;

const Container = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: black;
`;

const StyledLoadingLabel = styled.h3`
  color: #fff;
  margin: 0;
`;

const zoomConfig = {
  sdkKey: process.env.REACT_APP_ZOOM_SDK_KEY,
  sdkSecret: process.env.REACT_APP_ZOOM_SDK_SECRET,
}

const getLoadingMessage = (locale) => {
  switch (locale) {
    case 'ja': return '読み込んでいます...';
    case 'id-ID': return 'Memuat...';
    case 'zh-Hans': return '载入中...';
    case 'zh-Hant': return '載入中...';
    case 'ko': return '로드 중 ...';
    case 'en':
    default: return 'Loading...';
  }
}

const generateSignature = (meetConfig, timestamp) => {
  const { meetingNumber, role } = meetConfig;
  const iat = Math.round(timestamp / 1000) - 30;
  const exp = iat + 60 * 60 * 2
  const oHeader = { alg: 'HS256', typ: 'JWT' }
  const oPayload = {
    sdkKey: zoomConfig.sdkKey,
    mn: meetingNumber,
    role,
    iat: iat,
    exp: exp,
    appKey: zoomConfig.sdkKey,
    tokenExp: iat + 60 * 60 * 2
  }

  const sHeader = JSON.stringify(oHeader)
  const sPayload = JSON.stringify(oPayload)
  const signature = KJUR.jws.JWS.sign('HS256', sHeader, sPayload, zoomConfig.sdkSecret);
  return signature;
}

const App = () => {
  const parsedUrl = queryString.parseUrl(window.location.href);
  const { query: { link, userName, userEmail, locale } } = parsedUrl;
  console.log(parsedUrl);
  const { url, query: { pwd } } = queryString.parseUrl(link);
  const roomNumber = parseInt(url.split("/j/")[1]);
  const serverTime = useServerTime();

  const mapLocale = useCallback((locale) => {
    if (['en-US', 'de-DE', 'es-ES', 'fr-FR', 'jp-JP', 'pt-PT', 'ru-RU', 'zh-CN', 'zh-TW', 'ko-KO', 'vi-VN',  'it-IT'].includes(locale)) {
      return locale;
    }

    switch (locale) {
      case 'ja': return 'jp-JP';
      case 'zh-Hans': return 'zh-CN';
      case 'zh-HK': return 'zh-TW';
      case 'zh-Hant': return 'zh-TW';
      case 'ko': return 'ko-KO';
      case 'vi': return 'vi-VN';
      case 'it': return 'it-IT';
      case 'es': return 'es-ES';
      default: return 'en-US';
    }
  }, []);

  const { value: isSDKLoaded } = useAsync(async () => {
    const zoomLocale = locale ? mapLocale(locale) : 'en-US';
    // Multi-Lang Support
    return new Promise((resolve, _) => {
      ZoomMtg.preLoadWasm();
      ZoomMtg.prepareWebSDK();
      ZoomMtg.i18n.load(zoomLocale).then(() => {
        resolve(true);
      });
    });
  }, [locale, mapLocale]);

  const { value: isInited } = useAsync(async () => {
    if (!isSDKLoaded) return;

    return new Promise((resolve, reject) => {
      // ref: https://marketplacefront.zoom.us/sdk/meeting/web/ZoomMtg.html#init
      ZoomMtg.init({
        leaveUrl: window.location.href,
        isSupportAV: true,
        isLockBottom: false,
        success: () => {
          resolve(true);
        },
        error: (error) => {
          console.error(error);
          reject(error);
        },
      });
      
    });
  }, [ZoomMtg, isSDKLoaded]);

  useAsync(async () => {
    if (!isInited || !roomNumber || !serverTime) return;

    return new Promise((resolve, reject) => {
      const signature = generateSignature({ meetingNumber: roomNumber, role: 0 }, serverTime)
      ZoomMtg.join({
        meetingNumber: roomNumber,
        userName,
        userEmail,
        signature,
        sdkKey: zoomConfig.sdkKey,
        passWord: pwd,
        success: () => {
          resolve(true);
        },
        error: (error) => {
          console.error(error);
          reject(error);
        },
      });
    });
  }, [userEmail, userName, isInited, roomNumber, serverTime, pwd]);

  return (
    <Container>
      <GlobalStyle />
      {(!isSDKLoaded || !isInited) && (
        <>
          <CustomCircularProgress />
          <StyledLoadingLabel>{getLoadingMessage(locale)}</StyledLoadingLabel>
        </>
      )}
    </Container>
  );
}

export default App;
